<template>
  <div>
    <a-space :style="{ width: '100%' }">
      <h2
        :style="{
          marginTop: '10px',
          marginLeft: '10px',
        }"
      >
        <b> Welcome to Amica </b>,
        <i> create your account to access our system and find your amicas </i>.
      </h2>
    </a-space>
    <a-row>
      <a-col :sm="24" :md="14">
        <div class="carousel-container">
          <a-carousel autoplay :dots="false">
            <div>
              <img
                slot="cover"
                alt="example"
                :src="require('../../assets/carousel/slide_1.jpg')"
              />
              <p class="slogan">
                <b>
                  "Having someone wonder where you are when you don't come home
                  at night is a very old human need."
                </b>
                — Margart Mead
              </p>
            </div>

            <div>
              <img
                slot="cover"
                alt="example"
                :src="require('../../assets/carousel/slide_2.jpg')"
              />

              <p class="slogan">
                <b>
                  "Love cures people—both the ones who give it and the ones who
                  receive it."
                </b>
                — Karl Menninger
              </p>
            </div>

            <div>
              <img
                slot="cover"
                alt="example"
                :src="require('../../assets/carousel/slide_3.png')"
              />
              <p class="slogan">
                <b>
                  "You come to love not by finding the perfect person, but by
                  seeing an imperfect person perfectly."
                </b>
                — Sam Keen
              </p>
            </div>

            <div>
              <img
                slot="cover"
                alt="example"
                :src="require('../../assets/carousel/slide_4.jpg')"
              />
              <p class="slogan">
                <b>
                  "You know you're in love when you can't fall asleep because
                  reality is finally better than your dreams."
                </b>
                — Dr. Seuss
              </p>
            </div>
          </a-carousel>
        </div>
      </a-col>

      <a-col :sm="24" :md="10">
        <div
          :style="{
            margin: '20px',
          }"
        >
          <a-card :style="{ padding: '20px' }">
            <a-form @submit="handleRegistration">
              <div :style="{ width: '100%', textAlign: 'left' }">
                <h3>
                  <b> Registration Form </b>
                </h3>
                <a-row :gutter="16">
                  <a-col :sm="24">
                    <a-form-item>
                      <a-input placeholder="Firstname" v-model="firstname" />
                    </a-form-item>
                  </a-col>

                  <a-col :sm="24">
                    <a-form-item>
                      <a-input v-model="lastname" placeholder="Lastname" />
                    </a-form-item>
                  </a-col>
                </a-row>
              </div>

              <div>
                <a-row :gutter="16">
                  <a-col :sm="24" :md="12">
                    <a-form-item>
                      <a-date-picker
                        @change="handleSelectedDate"
                        v-model="birthday"
                        format="YYYY-MM-DD"
                        placeholder="Birthday"
                        :allowClear="false"
                        :style="{
                          width: '100%',
                        }"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col :sm="24" :md="12">
                    <a-form-item>
                      <a-select default-value="lucy" v-model="gender">
                        <a-select-option value=""> Select </a-select-option>
                        <a-select-option value="male"> Male </a-select-option>
                        <a-select-option value="female">
                          Female
                        </a-select-option>
                        <a-select-option value="gay"> Gay </a-select-option>
                        <a-select-option value="lesbian">
                          Lesbian
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
              </div>

              <a-form-item>
                <a-input v-model="address" placeholder="City/Province" />
              </a-form-item>

              <a-form-item>
                <a-input v-model="email" placeholder="Email" type="email" />
              </a-form-item>

              <a-form-item>
                <a-input
                  v-model="password"
                  type="password"
                  placeholder="password"
                />
              </a-form-item>

              <a-form-item>
                <a-input
                  v-model="con_password"
                  placeholder="Confirm password"
                  type="password"
                />
              </a-form-item>

              <a-space align="start" :style="{ width: '100%' }" size="large">
                <a-form-item>
                  <a-button
                    :loading="is_process"
                    type="primary"
                    html-type="submit"
                  >
                    Register
                  </a-button>
                </a-form-item>

                <a-form-item>
                  Already have an account?

                  <router-link to="/login"> Login here </router-link>
                </a-form-item>
              </a-space>

              <a-form-item>
                <a-button type="link" disabled> Forget password?</a-button>
              </a-form-item>
            </a-form>
          </a-card>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "Register",
  data() {
    return {
      firstname: "",
      lastname: "",
      birthday: moment("2015-01-01", "YYYY-MM-DD"),
      gender: "",
      address: "",
      email: null,
      password: "123456",
      con_password: "123456",
      is_process: false,
    };
  },
  methods: {
    // validateImage(info) {
    //   let valid = [];

    //   if (!info.target) {
    //     this.$message.error("Image is required.");
    //     valid = "false";
    //   } else {
    //     let file = info.target.files[0];
    //     const isLt2M = file.size / 1024 / 1024 < 2;
    //     const isJpgOrPng =
    //       file.type === "image/jpeg" || file.type === "image/png";

    //     if (!isJpgOrPng) {
    //       this.$message.error("You can only upload JPG file!");
    //       valid = "false";
    //     }
    //     if (!isLt2M) {
    //       this.$message.error("Image must smaller than 2MB!");
    //       valid = "false";
    //     }
    //   }

    //   return valid.length > 0 ? false : true;
    // },

    handleRegistration(e) {
      e.preventDefault();

      let err = [];

      if (this.firstname.trim() === "" || this.firstname.length === 0) {
        err = "error";
        this.$message.error("Firstname is required.");
      }
      if (this.lastname.trim() === "" || this.lastname.length === 0) {
        err = "error";
        this.$message.error("Lastname is required.");
      }

      if (this.gender.trim() === "" || this.gender.length === 0) {
        err = "error";
        this.$message.error("Gender is required.");
      }
      if (this.address.trim() === "" || this.address.length === 0) {
        err = "error";
        this.$message.error("Address is required.");
      }
      if (this.email.trim() === "" || this.email.length === 0) {
        err = "error";
        this.$message.error("Email is required.");
      }

      if (this.password.trim() === "" || this.password.length === 0) {
        err = "error";
        this.$message.error("Password is required.");
      } else {
        if (this.password.length <= 5) {
          err = "error";
          this.$message.error("Password must be greather than 5 character.");
        }

        if (this.con_password !== this.password) {
          err = "error";
          this.$message.error("Password is not match.");
        }
      }

      if (this.con_password.trim() === "" || this.con_password.length === 0) {
        err = "error";
        this.$message.error("Confirm password is required.");
      }

      if (err.length > 0) {
        console.log("form has an error.");
      } else {
        this.is_process = true;
        this.$http
          .post("/api/welcome/registration", {
            firstname: this.firstname,
            lastname: this.lastname,
            birthday: this.birthday,
            gender: this.gender,
            address: this.address,
            email: this.email,
            password: this.password,
            con_password: this.con_password,
          })
          .then((response) => {
            let data = response.data;
            if (data.message === "email-exist") {
              this.$message.error("Email address already registered.");
            }

            if (data.message === "success") {
              this.$message.success(
                "Account successfully created. You can now login."
              );

              setTimeout(() => {
                this.$router.push("/login");
              }, 2000);
            }
          })
          .catch((err) => console.log(err.message))
          .finally(() => (this.is_process = false));
      }
    },
    handleSelectedDate(date) {
      this.birthday = moment(date, "YYYY-MM-DD");
    },
  },
};
</script>

<style scoped>
.avatar-uploader {
  width: 128px;
  height: 128px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 50px;
  cursor: pointer;
  padding: 2;
}
.avatar-uploader > img {
  width: 128px;
  height: "auto";
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.upload-icon {
  font-size: 20px;
}

.carousel-container {
  width: "100%";
  margin: 20px;
}

.carousel-container img {
  width: 100%;
  height: auto;
  padding-left: 10px;
  padding-right: 10px;
}
.slogan {
  margin-top: 10px;
  font-size: 20px;
}
</style>
