const Store = {
  state: {
    count: 0,
    isLogin: false,
    user: null,
  },
  mutations: {
    increments(state) {
      state.count++;
    },
    _mutate_isLogin(state, payload) {
      state.isLogin = payload;
    },
    _mutate_user(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    increment({ commit }) {
      commit("increments");
    },

    _isLogin({ commit }, payload) {
      commit("_mutate_isLogin", payload);
    },

    _user({ commit }, payload) {
      commit("_mutate_user", payload);
    },

    async _silentLogin({ commit }, http) {
      let response = await http.get("/api/silent/me");
      if (response.data.message === "success") {
        commit("_mutate_user", response.data.user);
        commit("_mutate_isLogin", response.data.status);
      } else {
        commit("_mutate_user", null);
        commit("_mutate_isLogin", false);
      }
    },
  },
  getters: {
    isLogin(state) {
      return state.isLogin;
    },
    getUser(state) {
      return state.user;
    },
    getCount(state) {
      return state.count;
    },
  },
};

export default Store;
