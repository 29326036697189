import Vue from "vue";
import App from "./App.vue";
import "ant-design-vue/dist/antd.css";
import Antd from "ant-design-vue";
import VueRouter from "vue-router";
import Vuex from "vuex";
import Http from "./utils/Http";
import Routes from "./Routes";
import Store from "./Store";

Vue.config.productionTip = false;
Vue.prototype.$http = Http;

Vue.use(Antd);
Vue.use(VueRouter);
Vue.use(Vuex);

const store = new Vuex.Store(Store);
const router = new VueRouter(Routes);
new Vue({
  render: (h) => h(App),
  router: router,
  store: store,
}).$mount("#app");
