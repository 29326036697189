<template>
  <a-row>
    <a-col :xs="24" :md="16">
      <div class="login-container">
        <div>
          <h1>AMICA</h1>
          <h5>FIND YOUR AMICAS WITHOUT LIMITATION</h5>
        </div>
      </div>
    </a-col>
    <a-col :xs="24" :md="8">
      <div class="login-form">
        <a-card>
          <div :style="{ textAlign: 'left', marginBottom: '20px' }">
            <h3>
              <b> Login Form </b>
            </h3>
          </div>

          <a-form @submit="handleLogin">
            <a-form-item>
              <a-input placeholder="Email" v-model="email" type="email" />
            </a-form-item>

            <a-form-item>
              <a-input
                placeholder="Password"
                v-model="password"
                type="password"
              />
            </a-form-item>

            <a-space direction="vertical" size="middle">
              <div>
                <a-button
                  :loading="is_process"
                  :disabled="is_successful"
                  type="primary"
                  html-type="submit"
                  :style="{ width: '100%' }"
                >
                  Login
                </a-button>
              </div>

              <div>
                <a-button type="link" disabled> forget password?</a-button>
              </div>

              <div>
                <router-link to="/register"> Create an account </router-link>
              </div>
            </a-space>
          </a-form>
        </a-card>
      </div>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      is_process: false,
      is_successful: false,
    };
  },
  methods: {
    handleLogin(e) {
      e.preventDefault();

      let err = [];

      if (this.email.trim() === "" || this.email.length === 0) {
        err = "error";
        this.$message.error("Email is required.");
      }

      if (this.password.trim() === "" || this.password.length === 0) {
        err = "error";
        this.$message.error("Password is required.");
      }

      if (err.length > 0) {
        console.log("form has an error.");
      } else {
        this.is_process = true;
        this.$http
          .post("/api/welcome/signin", {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            let data = response.data;
            if (data.message === "email-invalid") {
              this.$message.error("Email address invalid.");
            }

            if (data.message === "password-invalid") {
              this.$message.error("Password is not correct.");
            }

            if (data.message === "success") {
              this.is_successful = true;
              localStorage.setItem("auth_token", data.auth_token);
              this.$message
                .loading("Login successful, please wait...", 2.5)
                .then(() => {
                  this.$store.dispatch("_user", data.user);
                  this.$store.dispatch("_isLogin", true);
                  this.$router.push("/user");
                });
            }
          })
          .catch((err) => console.log(err.message))
          .finally(() => (this.is_process = false));
      }
    },
  },
};
</script>

<style scoped>
.login-form {
  height: calc(100vh - 70px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  height: calc(100vh - 70px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 765px) {
  .login-form {
    height: auto;
    width: 100%;
  }

  .login-container {
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
