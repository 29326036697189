import { io } from "socket.io-client";

class SocketioService {
  socket;
  setupSocketConnection() {
    this.socket = io.connect(process.env.VUE_APP_SOCKET_ENDPOINT, {
      query: {
        token: localStorage.getItem("auth_token"),
      },
    });
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  getSocket() {
    return this.socket;
  }
}

export default new SocketioService();
