<template>
  <div>
    <!-- welcome menu -->
    <a-menu
      theme="dark"
      mode="horizontal"
      v-model="key"
      class="menu"
      v-if="!isLogin"
    >
      <a-menu-item class="menu-item" key="Home">
        <router-link to="/"> <a-icon type="home" /> Home </router-link>
      </a-menu-item>

      <a-menu-item class="menu-item" key="Login">
        <router-link to="/login"> <a-icon type="login" /> Login </router-link>
      </a-menu-item>

      <a-menu-item class="menu-item" key="Register">
        <router-link to="/register">
          <a-icon type="form" /> Register
        </router-link>
      </a-menu-item>
    </a-menu>

    <!-- users menu -->
    <!-- key is set to route name to redirect selected route color -->
    <!-- <a-menu theme="dark" mode="horizontal" v-model="key" class="menu" v-else>
      <a-menu-item class="menu-item" key="UserHome">
        <router-link to="/user"> <a-icon type="home" /> User name </router-link>
      </a-menu-item>

      <a-menu-item class="menu-item" key="GlobalChat">
        <router-link to="/user/global-chat">
          <a-icon type="message" /> Chat  
        </router-link>
      </a-menu-item>

      <a-menu-item class="menu-item" key="Signout">
        <router-link to="/user/signout">
          <a-icon type="login" /> Signout
        </router-link>
      </a-menu-item>
    </a-menu> -->
  </div>
</template>

<script>
export default {
  name: "Routers",
  data() {
    return {
      key: [`${this.$route.name}`],
    };
  },
  computed: {
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
};
</script>

<style scoped>
.menu {
  line-height: 60px;
  display: flex;

  justify-content: flex-end;
}
</style>
