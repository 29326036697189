import axios from "axios";

const Http = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
  },
});

export default Http;
