<template>
  <div class="w-100">
    <a-row type="flex" justify="space-around" align="middle">
      <a-col :xs="22">
        <a-space>
          <div>
            <a-avatar
              :size="120"
              alt="amica profile"
              :src="require('@/assets/amica-profile.png')"
              class="profile"
            />
          </div>

          <div>
            <a-space direction="vertical">
              <div class="profile-name">Jhomar Baluca</div>

              <div>March 2, 1994 | Male</div>
              <div>Davao City, Davao Del Sur</div>
            </a-space>
          </div>
        </a-space>
      </a-col>

      <a-col :xs="2">
        <a-space direction="vertical">
          <div>
            <a-popover v-model="visible" trigger="click" placement="bottom">
              <div slot="content">
                <a-space direction="vertical" :size="10">
                  <div>
                    <router-link to="/user/signout">
                      <a-icon type="login" /> Profile
                    </router-link>
                  </div>

                  <div>
                    <router-link to="/user/signout">
                      <a-icon type="logout" /> Image
                    </router-link>
                  </div>

                  <div>
                    <router-link to="/user/signout">
                      <a-icon type="logout" /> Signout
                    </router-link>
                  </div>
                </a-space>
              </div>
              <a-button shape="circle" icon="more" size="large" />
            </a-popover>
          </div>
        </a-space>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "BasicInfo",
  data() {
    return {
      visible: false,
    };
  },
};
</script>

<style scoped>
.w-100 {
  width: 100%;
  border: 1px solid #ccc;
  text-align: left;
  padding: 10px;
  line-height: 15px;
}
.profile {
  border: 1px solid #ccc;
}
.profile-name {
  font-size: 1.5em;
}
</style>
