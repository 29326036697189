import Home from "./components/welcome/Home.vue";
import Login from "./components/welcome/Login.vue";
import Register from "./components/welcome/Register.vue";
import PageNotFound from "./components/PageNotFound";
import GlobalChat from "./components/global/GlobalChat.vue";
import UserHome from "./components/users/index.vue";
import Store from "./Store";
import axios from "axios";

const isAuthenticated = async () => {
  let token = localStorage.getItem("auth_token");
  if (token) {
    axios.defaults.headers.common[`Authorization`] = `Bearer ${token}`;
    let response = await axios.get(`${process.env.VUE_APP_API}api/silent/me`);
    if (response.data.message === "success") {
      Store.state.user = response.data.user;
      Store.state.isLogin = true;
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const handleSignout = () => {
  Store.state.user = null;
  Store.state.isLogin = false;
  axios.defaults.headers.common[`Authorization`] = null;
  localStorage.removeItem("auth_token");

  return true;
};

const _routes = [
  // welcomes route
  {
    path: "/",
    component: Home,
    name: "Home",
    async beforeEnter(to, from, next) {
      if (await isAuthenticated()) {
        next("/user");
      } else {
        next();
      }
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    async beforeEnter(to, from, next) {
      if (await isAuthenticated()) {
        next("/user");
      } else {
        next();
      }
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    async beforeEnter(to, from, next) {
      if (await isAuthenticated()) {
        next("/user");
      } else {
        next();
      }
    },
  },

  // users route
  {
    path: "/user",
    name: "UserHome",
    component: UserHome,
    async beforeEnter(to, from, next) {
      if (await isAuthenticated()) {
        next();
      } else {
        next("/login");
      }
    },
  },

  {
    path: "/user/global-chat",
    name: "GlobalChat",
    component: GlobalChat,
    async beforeEnter(to, from, next) {
      if (await isAuthenticated()) {
        next();
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/user/signout",
    name: "Signout",
    async beforeEnter(to, from, next) {
      if (handleSignout()) {
        next("/login");
      } else next(false);
    },
  },
  {
    path: "*",
    name: "Not Found",
    component: PageNotFound,
  },
];

const Routes = {
  routes: _routes,
};

export default Routes;
