<template>
  <div style="padding-top: 10px">
    <div>Welcome to amica [public]</div>
    <div style="color: red">Dont share anything private</div>

    <div class="global-chat" ref="messagesContainer">
      <div v-for="(message, key) in messages" :key="key">
        <div style="text-align: left; padding: 10px">
          <b> {{ message.fullname }} : </b> {{ message.message }}
        </div>
      </div>
    </div>

    <div style="margin-top: 10px; padding-left: 10px; padding-right: 10px">
      <div>
        <a-input-search
          placeholder="Message"
          v-model="message"
          @search="sendChat"
        >
          <a-button type="primary" slot="enterButton"> send </a-button>
        </a-input-search>
      </div>
    </div>
  </div>
</template>

<script>
import SocketioService from "../../utils/Socket";
export default {
  name: "GlobalChat",
  data() {
    return {
      userId: this.$store.getters.getUser._id,
      fullname: `${this.$store.getters.getUser.firstname} ${this.$store.getters.getUser.lastname}`,
      message: "",
      messages: [],
    };
  },
  methods: {
    generateId() {
      SocketioService.getSocket().emit("joinChat", {
        message: `${this.fullname} is joining.`,
        fullname: this.fullname,
        userId: this.userId,
      });
    },

    sendChat() {
      if (!this.message.trim()) {
        alert("Message is required");
        return;
      }

      let xdata = {
        message: this.message,
        fullname: this.fullname,
        userId: this.userId,
      };
      SocketioService.getSocket().emit("sendChat", xdata);
      this.message = "";
    },
    scrollToEnd: function () {
      var content = this.$refs.messagesContainer;
      content.scrollTop = content.scrollHeight;
    },
  },
  beforeMount() {
    this.generateId();
  },

  created() {
    SocketioService.setupSocketConnection();
  },
  mounted() {
    let that = this;
    SocketioService.getSocket().on("sendChat", (data) => {
      that.messages.push(data);
      this.scrollToEnd();
    });
  },
  beforeDestroy() {
    SocketioService.disconnect();
  },
};
</script>

<style scoped>
.w-100 {
  width: 100%;
}
.global-chat {
  height: calc(95vh - 90px);
  border: 1px solid #ccc;
  margin: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
