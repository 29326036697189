<template>
  <div class="w-100">this is amicas list</div>
</template>

<script>
export default {
  name: "Amicas",
};
</script>

<style scoped>
.w-100 {
  width: 100%;
  border-bottom: 1px solid #ccc;
  text-align: left;
  padding: 10px;
  line-height: 15px;
}
</style>
