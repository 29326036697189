<template>
  <div>Page not found</div>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>

<style>
</style>