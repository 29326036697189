var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-space',{style:({ width: '100%' })},[_c('h2',{style:({
        marginTop: '10px',
        marginLeft: '10px',
      })},[_c('b',[_vm._v(" Welcome to Amica ")]),_vm._v(", "),_c('i',[_vm._v(" create your account to access our system and find your amicas ")]),_vm._v(". ")])]),_c('a-row',[_c('a-col',{attrs:{"sm":24,"md":14}},[_c('div',{staticClass:"carousel-container"},[_c('a-carousel',{attrs:{"autoplay":"","dots":false}},[_c('div',[_c('img',{attrs:{"slot":"cover","alt":"example","src":require('../../assets/carousel/slide_1.jpg')},slot:"cover"}),_c('p',{staticClass:"slogan"},[_c('b',[_vm._v(" \"Having someone wonder where you are when you don't come home at night is a very old human need.\" ")]),_vm._v(" — Margart Mead ")])]),_c('div',[_c('img',{attrs:{"slot":"cover","alt":"example","src":require('../../assets/carousel/slide_2.jpg')},slot:"cover"}),_c('p',{staticClass:"slogan"},[_c('b',[_vm._v(" \"Love cures people—both the ones who give it and the ones who receive it.\" ")]),_vm._v(" — Karl Menninger ")])]),_c('div',[_c('img',{attrs:{"slot":"cover","alt":"example","src":require('../../assets/carousel/slide_3.png')},slot:"cover"}),_c('p',{staticClass:"slogan"},[_c('b',[_vm._v(" \"You come to love not by finding the perfect person, but by seeing an imperfect person perfectly.\" ")]),_vm._v(" — Sam Keen ")])]),_c('div',[_c('img',{attrs:{"slot":"cover","alt":"example","src":require('../../assets/carousel/slide_4.jpg')},slot:"cover"}),_c('p',{staticClass:"slogan"},[_c('b',[_vm._v(" \"You know you're in love when you can't fall asleep because reality is finally better than your dreams.\" ")]),_vm._v(" — Dr. Seuss ")])])])],1)]),_c('a-col',{attrs:{"sm":24,"md":10}},[_c('div',{style:({
          margin: '20px',
        })},[_c('a-card',{style:({ padding: '20px' })},[_c('a-form',{on:{"submit":_vm.handleRegistration}},[_c('div',{style:({ width: '100%', textAlign: 'left' })},[_c('h3',[_c('b',[_vm._v(" Registration Form ")])]),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"sm":24}},[_c('a-form-item',[_c('a-input',{attrs:{"placeholder":"Firstname"},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}})],1)],1),_c('a-col',{attrs:{"sm":24}},[_c('a-form-item',[_c('a-input',{attrs:{"placeholder":"Lastname"},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}})],1)],1)],1)],1),_c('div',[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"sm":24,"md":12}},[_c('a-form-item',[_c('a-date-picker',{style:({
                        width: '100%',
                      }),attrs:{"format":"YYYY-MM-DD","placeholder":"Birthday","allowClear":false},on:{"change":_vm.handleSelectedDate},model:{value:(_vm.birthday),callback:function ($$v) {_vm.birthday=$$v},expression:"birthday"}})],1)],1),_c('a-col',{attrs:{"sm":24,"md":12}},[_c('a-form-item',[_c('a-select',{attrs:{"default-value":"lucy"},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_c('a-select-option',{attrs:{"value":""}},[_vm._v(" Select ")]),_c('a-select-option',{attrs:{"value":"male"}},[_vm._v(" Male ")]),_c('a-select-option',{attrs:{"value":"female"}},[_vm._v(" Female ")]),_c('a-select-option',{attrs:{"value":"gay"}},[_vm._v(" Gay ")]),_c('a-select-option',{attrs:{"value":"lesbian"}},[_vm._v(" Lesbian ")])],1)],1)],1)],1)],1),_c('a-form-item',[_c('a-input',{attrs:{"placeholder":"City/Province"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1),_c('a-form-item',[_c('a-input',{attrs:{"placeholder":"Email","type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('a-form-item',[_c('a-input',{attrs:{"type":"password","placeholder":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('a-form-item',[_c('a-input',{attrs:{"placeholder":"Confirm password","type":"password"},model:{value:(_vm.con_password),callback:function ($$v) {_vm.con_password=$$v},expression:"con_password"}})],1),_c('a-space',{style:({ width: '100%' }),attrs:{"align":"start","size":"large"}},[_c('a-form-item',[_c('a-button',{attrs:{"loading":_vm.is_process,"type":"primary","html-type":"submit"}},[_vm._v(" Register ")])],1),_c('a-form-item',[_vm._v(" Already have an account? "),_c('router-link',{attrs:{"to":"/login"}},[_vm._v(" Login here ")])],1)],1),_c('a-form-item',[_c('a-button',{attrs:{"type":"link","disabled":""}},[_vm._v(" Forget password?")])],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }