<template>
  <div>
    <a-row>
      <a-col :xs="24" :md="14" :lg="16">
        <div>
          <a-row>
            <a-col :xs="24">
              <basicinfo />
            </a-col>

            <a-col :xs="24">
              <amicas />
            </a-col>
          </a-row>
        </div>
      </a-col>

      <a-col :xs="24" :md="10" :lg="8">
        <globalchat />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import BasicInfo from "./BasicInfo.vue";
import Amicas from "./amicas/index.vue";
import GlobalChat from "../global/GlobalChat.vue";

export default {
  name: "UserHome",
  components: {
    basicinfo: BasicInfo,
    amicas: Amicas,
    globalchat: GlobalChat,
  },
};
</script>

<style></style>
